/* eslint-disable */
import { Box, useMediaQuery, useTheme } from '@mui/material'
import React, { useState, useRef, useEffect } from 'react'
import mapboxglSupported from '@mapbox/mapbox-gl-supported'
import { GeoJSONSource, Map } from 'mapbox-gl'
// @ts-ignore
// eslint-disable-next-line
import mapboxgl from '!mapbox-gl'
import 'mapbox-gl/dist/mapbox-gl.css'
import { MAPBOX_TOKEN } from '../../../constants'
import { OnboardingBackgroundProps } from './OnboardingBackground'
import StaticMap from '../../../images/large-static-map.webp'

const mapboxConfigs = {
  desktop: {
    center: [-96.9903, 37.7392],
    startingZoom: 3.2,
    bounds: [
      [-139.975, 10.195], // Southwest coordinates
      [-56.161, 62.204], // Northeast coordinates
    ],
  },
  tablet: {
    center: [-104.9903, 39.7392],
    startingZoom: 0,
    bounds: [
      [-139.975, 10.195], // Southwest coordinates
      [-56.161, 62.204], // Northeast coordinates
    ],
  },
  mobile: {
    center: [-104.9903, 39.7392],
    startingZoom: 0,
    bounds: [
      [-127.975, 12.195], // Southwest coordinates
      [-64.161, 56.204], // Northeast coordinates
    ],
  },
}

const MapOnboardingBackground = (props: OnboardingBackgroundProps) => {
  const { currentFormState } = props
  const [mapboxMap, setMapboxMap] = useState<Map>()
  const [mapLoaded, setMapLoaded] = useState(false)
  const [useFallback, setUseFallback] = useState(false)
  const theme = useTheme()
  const isTablet = useMediaQuery(theme.breakpoints.down('md'))
  const { center, startingZoom } = mapboxConfigs.desktop

  const mapContainerRef = useRef<any>(null)

  useEffect(() => {
    if (mapboxglSupported.supported() && mapboxgl?.Map) {
      const map = new mapboxgl.Map({
        container: mapContainerRef.current,
        accessToken: MAPBOX_TOKEN,
        style: 'mapbox://styles/flocktech/clb17ybq2007714o2pl2reu73',
        center,
        zoom: startingZoom,
      })

      const size = 100

      const pulsingDot: any = {
        width: size,
        height: size,
        data: new Uint8Array(size * size * 4),
        context: {},

        // When the layer is added to the map,
        // get the rendering context for the map canvas.
        onAdd: function () {
          const canvas = document.createElement('canvas')
          canvas.width = this.width
          canvas.height = this.height
          this.context = canvas.getContext('2d')
        },

        // Call once before every frame where the icon will be used.
        render: function () {
          const duration = 1000
          const t = (performance.now() % duration) / duration

          const radius = (size / 2) * 0.3
          const outerRadius = (size / 2) * 0.7 * t + radius
          const context = this.context

          // Draw the outer circle.
          context.clearRect(0, 0, this.width, this.height)
          context.beginPath()
          context.arc(
            this.width / 2,
            this.height / 2,
            outerRadius,
            0,
            Math.PI * 2
          )
          context.fillStyle = `rgba(201, 66, 97, ${1 - t})`
          context.fill()

          // Draw the inner circle.
          context.beginPath()
          context.arc(this.width / 2, this.height / 2, radius, 0, Math.PI * 2)
          context.fillStyle = theme.palette.mulberry.main
          context.strokeStyle = 'white'
          context.lineWidth = 2 + 4 * (1 - t)
          context.fill()
          context.stroke()

          // Update this image's data with data from the canvas.
          this.data = context.getImageData(0, 0, this.width, this.height).data

          // Continuously repaint the map, resulting
          // in the smooth animation of the dot.
          map.triggerRepaint()

          // Return `true` to let the map know that the image was updated.
          return true
        },
      }

      map.on('load', () => {
        map.addImage('pulsing-dot', pulsingDot, { pixelRatio: 2 })

        map.addSource('dot-point', {
          type: 'geojson',
          data: {},
        })
        map.addLayer({
          id: 'layer-with-pulsing-dot',
          type: 'symbol',
          source: 'dot-point',
          layout: {
            'icon-image': 'pulsing-dot',
          },
        })
        setMapLoaded(true)
      })

      setMapboxMap(map)
    } else {
      setUseFallback(true)
    }
  }, [])

  useEffect(() => {
    if (mapboxMap && currentFormState.address) {
      const { lat, lng } = currentFormState.address
      if (isTablet) {
        mapboxMap.flyTo({ center: [lng!, lat! + 0.002], zoom: 15, speed: 2 })
      } else {
        mapboxMap.flyTo({ center: [lng!, lat!], zoom: 15, speed: 2 })
      }

      ;(mapboxMap.getSource('dot-point') as GeoJSONSource)?.setData({
        type: 'FeatureCollection',
        features: [
          {
            type: 'Feature',
            properties: {},
            geometry: {
              type: 'Point',
              coordinates: [lng!, lat!], // icon position [lng, lat]
            },
          },
        ],
      })
    }
  }, [currentFormState])

  return (
    <>
      <Box
        position="absolute"
        height="100%"
        width={{
          xs: '100vw',
          md: '50vw',
        }}
        left="0px"
        sx={{
          transition: 'opacity 300ms ease',
          backgroundColor: '#D3DFE8',
          opacity: mapLoaded ? 1 : 0,
        }}
        zIndex={-2}
      >
        <Box
          id="map"
          ref={mapContainerRef}
          position="absolute"
          width="100%"
          height="100%"
        />
      </Box>
      {useFallback && (
        <Box
          position="absolute"
          height="100%"
          width={{
            xs: '100vw',
            md: '50vw',
          }}
          left="0px"
          zIndex={-1}
          sx={{
            background: `url("${StaticMap}")`,
            backgroundSize: 'cover',
          }}
        />
      )}
      <Box
        position="absolute"
        height="100%"
        width={{
          xs: '100vw',
          md: '50vw',
        }}
        left="0px"
        zIndex={-1}
        sx={{
          background: `rgba(235, 246, 255, 0.1);`,
          backdropFilter: `blur(2px)`,
          transition: 'opacity 0.5s ease',
          opacity: currentFormState.address && !useFallback ? 0 : 1,
        }}
      />
    </>
  )
}

export default MapOnboardingBackground
