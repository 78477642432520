import { Box, useMediaQuery, useTheme } from '@mui/material'
import React, { useEffect, useState } from 'react'

// @ts-ignore
// eslint-disable-next-line
import mapboxgl from '!mapbox-gl'
import 'mapbox-gl/dist/mapbox-gl.css'

export type OnboardingBackgroundProps = {
  currentFormState: { [key: string]: any }
  hideTabletBackground?: boolean
}

export type StaticOnboardingBackgroundProps =
  Partial<OnboardingBackgroundProps> & {
    src?: string
    backgroundTransition?: boolean
  }

export const StaticOnboardingBackground = (
  props: StaticOnboardingBackgroundProps
) => {
  const { src, hideTabletBackground, backgroundTransition } = props
  const [imageLoaded, setImageLoaded] = useState(false)

  useEffect(() => {
    setImageLoaded(true)
  }, [])

  const theme = useTheme()
  const isTablet = useMediaQuery(theme.breakpoints.down('md'))

  if (isTablet && hideTabletBackground) {
    return null
  }

  return (
    <>
      <Box
        position="absolute"
        height="100%"
        width={{
          xs: '100vw',
          md: '50vw',
        }}
        left="0px"
        zIndex={-2}
        sx={{
          transition: backgroundTransition ? 'opacity 300ms ease' : undefined,
          opacity: imageLoaded ? 1 : 0,
          backgroundImage: `url("${src}")`,
          backgroundSize: 'cover',
          backgroundPosition: 'top',
        }}
      />
      <Box
        position="absolute"
        height="100%"
        width={{
          xs: '100vw',
          md: '50vw',
        }}
        left="0px"
        zIndex={-1}
        sx={{
          // background: `linear-gradient(180deg, rgba(235, 246, 255, 0) 0%, rgba(235, 246, 255, 0.8) 54.69%)`,
          // backdropFilter: `blur(2px)`,
          backgroundColor: !isTablet ? 'midnightBlue.main' : 'gray1.main',
          opacity: 0.8,
        }}
      />
    </>
  )
}
