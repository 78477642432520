import React from 'react'
import * as Sentry from '@sentry/gatsby'
import { createLeadHelper, localStore } from '@flock/utils'
import {
  BathroomIcon,
  BedroomIcon,
  DimensionsIcon,
  HouseOutlinedIcon,
  InputType,
  StepConfig,
  AnyInputConfig,
} from '@flock/shared-ui'
import { Box, CircularProgress, Typography } from '@mui/material'
import { Core_HomeDetails } from '@flock/flock-gql-server/src/__generated__/graphql'
import { navigate } from 'gatsby'
import {
  abbreviationToState,
  decomposeSlackUrl,
  isValidMarket,
  prettyPrintStates,
  prettyPrintStatesAbbr,
} from '../utils'
import WebflowStep, {
  InjectedWebflowStepProps,
  WebflowStepProps,
} from './WebflowStep'
import {
  WebflowData,
  SearchLeadsByAddressLead,
  WebflowFunctions,
} from './webflowTypes'
import PlaceholderHouse from '../../images/placeholder-house.webp'

import { GOOGLE_MAPS_API_KEY } from '../../constants'

import OnboardingSplash from '../../images/onboarding-splash.webp'
import OverheadHouse from '../../images/overhead-house.webp'

import { flattenUpdateLeadHelper } from '../../api'

import {
  OnboardingBackgroundProps,
  StaticOnboardingBackground,
  StaticOnboardingBackgroundProps,
} from './BackgroundComponents/OnboardingBackground'
import MapOnboardingBackground from './BackgroundComponents/MapOnboardingBackground'
import PropertyDetailBackground, {
  PropertyDetailBackgroundProps,
} from './BackgroundComponents/PropertyDetailBackground'

import ConditionBackground from './BackgroundComponents/ConditionBackground'
import LoadingBackground, {
  LoadingBackgroundProps,
} from './BackgroundComponents/LoadingBackground'
import {
  HOMEPAGE_PATH,
  OFFER_PAGE_PATH,
  ONBOARDING_PATH,
} from '../../routeConstants'

import generateGoogleMapUrl from '../../utils/googleMapUrl'

const injectProps =
  (stepName: keyof WebflowData) => (flowData: Partial<WebflowData>) => {
    let headline = ``
    let description = ``

    if (stepName === 'dstLeadGen') {
      headline = `We'll be in ${
        abbreviationToState[flowData.address?.address?.state!] || `your area`
      } soon.`
      description = `We currently do not take homes in ${
        abbreviationToState[flowData.address?.address?.state!] ||
        `your property's state`
      }. We take homes in ${prettyPrintStates(
        'and'
      )}. Leave your contact info and we'll be in touch soon!`

      if (
        [
          'duplex',
          'triplex',
          'fourplex',
          'other',
          'condo',
          'townhouse',
        ].includes(flowData.homeDetails?.propertyType!)
      ) {
        headline = `We'll be in touch.`
        description = `We only take single family homes in ${prettyPrintStatesAbbr(
          'and'
        )}. Leave your contact info and we'll be in touch soon!`
      }

      if (
        ['duplex', 'triplex', 'fourplex'].includes(
          flowData?.homeDetails?.propertyType!
        ) &&
        flowData?.homeDetails?.ownsWholeBuilding?.toString() === 'false'
      ) {
        headline = `We'll be in touch.`
        description = `We only take single family homes in ${prettyPrintStatesAbbr(
          'and'
        )}. Leave your contact info and we'll be in touch soon!`
      }
    }
    if (headline && description) {
      return {
        flowData,
        stepData: flowData[stepName] || {},
        headline,
        description,
      }
    }
    return {
      flowData,
      stepData: flowData[stepName] || {},
    }
  }

const basicProps = (stepName: keyof WebflowData) => ({
  stepName,
  component: WebflowStep,
  injectProps: injectProps(stepName),
  onCompletion: async (
    flowData: Partial<WebflowData>,
    functions: WebflowFunctions
  ) => {
    const { updateLead } = functions
    await flattenUpdateLeadHelper(
      flowData,
      flowData.leadUuid as string,
      updateLead
    )
    return flowData
  },
})

const getCompletedLeadWithSameContactInfo = (
  flowData: Partial<WebflowData>,
  leads: SearchLeadsByAddressLead[]
) => {
  const { duplicateAddressVerification } = flowData
  const { fullName, email, phoneNumber } = duplicateAddressVerification!
  return leads.find((currentLead: any) => {
    let isDstLead = false
    let error = false
    if (currentLead.slackThreadUrl && currentLead.answers) {
      try {
        const parsedJsonAnswers = JSON.parse(currentLead.answers)
        isDstLead = parsedJsonAnswers.dstLead === true
      } catch (e) {
        error = true
      }
    }

    return (
      !error &&
      !isDstLead &&
      currentLead.fullName &&
      (currentLead.phoneNumber || currentLead.email) &&
      currentLead.fullName === fullName &&
      (currentLead.phoneNumber === phoneNumber ||
        currentLead.email === email) &&
      currentLead.slackThreadUrl
    )
  })
}

// Determines if we should shortcut directly to the offer page and provides the offer page lead UUID to redirect to or null if it does not exist
// TODO: WZ - make this generic so that it isn't specific to these brokers
const shouldRedirectToAgentError = (leads: SearchLeadsByAddressLead[]) =>
  leads?.some((currentLead: SearchLeadsByAddressLead) =>
    [
      'venturerei',
      'atlas',
      'swifthomesolutions',
      'acquiresfr',
      'mavrealty',
    ].includes(
      currentLead?.fullName?.trim().toLowerCase().replace(/\s+/g, '') as string
    )
  )

export const initialContactInfoStep: StepConfig<
  WebflowData,
  WebflowFunctions,
  WebflowStepProps<StaticOnboardingBackgroundProps>,
  InjectedWebflowStepProps
> = {
  ...basicProps('initialContactInfo'),
  onBack: () => {
    window.onbeforeunload = null
    navigate(HOMEPAGE_PATH)
  },
  componentProps: {
    stepName: 'contactInfo',
    headline: `Request an offer in 5 minutes.`,
    description:
      'We use this contact info to send an official offer, underwritten by our experts, within 24 hours.',
    progress: 'profile',
    spacing: 3,
    hideBack: true,
    backgroundComponent: StaticOnboardingBackground,
    backgroundComponentProps: {
      src: OnboardingSplash,
      applyFilter: true,
      backgroundTransition: true,
    },
    inputConfigs: [
      {
        name: 'title',
        type: InputType.CustomComponent,
        required: true,
        gridItemProps: {
          display: {
            md: 'none',
          },
        },
        props: {
          component: (
            <Typography
              variant="h1"
              color="midnightBlue.main"
              pb={{ xs: '24px', sm: '40px' }}
            >
              Request an offer in 5 minutes
            </Typography>
          ),
        },
      },
      {
        name: 'fullName',
        type: InputType.Text,
        required: true,
        props: {
          label: 'Full Name',
          size: 'large',
        },
        mobileProps: {
          size: 'small',
        },
      },
      {
        name: 'email',
        type: InputType.Text,
        required: true,
        props: {
          label: 'Email',
          format: 'email',
          size: 'large',
        },
        mobileProps: {
          size: 'small',
        },
      },
      {
        name: 'phoneNumber',
        type: InputType.Text,
        required: false,
        props: {
          label: 'Phone Number (optional)',
          format: 'phone',
          size: 'large',
        },
        mobileProps: {
          size: 'small',
        },
      },
    ],
  },
  onCompletion: async (
    flowData: Partial<WebflowData>,
    functions: WebflowFunctions
  ) => {
    // If the lead already exists, then we just update the address
    // Otherwise, we create a new lead
    const { initialContactInfo, source } = flowData
    const { createLead } = functions
    const dataToSubmit = {
      contactInfo: initialContactInfo,
      source,
    }
    const queryParams = localStore?.getItem('queryParams')
    let parsedQueryParams: any = {}
    if (queryParams) {
      parsedQueryParams = JSON.parse(queryParams)
    }
    const createLeadResponse = await createLeadHelper(
      dataToSubmit,
      parsedQueryParams,
      createLead
    )
    const newLeadUuid = createLeadResponse.data.createLead.lead.uuid as string
    // Clear out the lead uuid in the url
    const newPath = `${ONBOARDING_PATH}?leadUuid=${newLeadUuid}`
    window.history.replaceState(null, '', newPath)
    const newData = {
      ...flowData,
      leadUuid: newLeadUuid,
    }

    if (flowData.initialContactInfo) {
      newData.duplicateAddressVerification = {
        ...flowData.initialContactInfo,
      }
    }

    return newData
  },
}

const handleRedirectDuplicateToOfferPage = async (
  flowData: Partial<WebflowData>,
  functions: WebflowFunctions
) => {
  // If the contact info matches the existing lead, we go straight to the valuation page
  const { address, leadUuid } = flowData
  const { searchLeadsByAddress, deleteLead, postSlackMessage } = functions
  const sameAddressLeadsResponse = await searchLeadsByAddress({
    input: { addressUuid: address!.address.addressUuid },
  })
  const existingLead = getCompletedLeadWithSameContactInfo(
    flowData,
    (sameAddressLeadsResponse.data?.searchLeadsByAddress
      ?.leads as SearchLeadsByAddressLead[]) || []
  )
  // If this lead exists, delete the existing lead and navigate to the existing offer page. If we fail to delete it, silently fail.
  if (existingLead && existingLead.uuid !== leadUuid) {
    try {
      const { channel, threadTimestamp } = decomposeSlackUrl(
        existingLead?.slackThreadUrl as string
      )
      await postSlackMessage({
        variables: {
          postSlackMessageInput: {
            channel,
            threadTimestamp,
            text: 'This lead just entered their duplicate address and contact info and visited the valuation page.',
          },
        },
      })
      await deleteLead({
        variables: { deleteLeadInput: { leadUuid: leadUuid as string } },
      })
    } catch (e) {
      Sentry.captureException(
        new Error('Failed to post slack message and delete duplicate lead'),
        {
          extra: {
            leadUuid,
          },
        }
      )
    }

    window.onbeforeunload = null
    window.open(`${OFFER_PAGE_PATH}/${existingLead.uuid}`, '_self')
    if (window.top) {
      window.top.postMessage('Close Modal', '*')
    }

    // This line ensures that the next step doesn't render.
    // @ts-ignore
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    await new Promise((resolve) => {})
  }
  return flowData
}

export const enterAddressStep: StepConfig<
  WebflowData,
  WebflowFunctions,
  WebflowStepProps<OnboardingBackgroundProps>,
  InjectedWebflowStepProps
> = {
  ...basicProps('address'),
  prerender: async (flowData: Partial<WebflowData>) => {
    // Clear out any existing address here
    const newWebflowData = { ...flowData }
    delete newWebflowData.address
    return newWebflowData
  },
  componentProps: {
    stepName: 'address',
    progress: 'address',
    hideBack: true,
    backgroundComponent: MapOnboardingBackground,
    inputConfigs: [
      {
        name: 'address',
        type: InputType.Address,
        required: true,
        props: {
          label: 'Your rental property address',
          size: 'large',
          placeholder: '123 Main St, Destin, FL 32541',
        },
        mobileProps: {
          size: 'small',
        },
      },
    ],
  },
  onCompletion: async (
    flowData: Partial<WebflowData>,
    functions: WebflowFunctions
  ) => {
    // If the lead already exists, then we just update the address
    // Otherwise, we create a new lead
    if (flowData.leadUuid && flowData.address?.address?.addressUuid) {
      const { updateLead } = functions
      await flattenUpdateLeadHelper(flowData, flowData.leadUuid, updateLead)

      const newData = { ...flowData }
      // If it's not a valid market, this is a DST lead.
      if (!isValidMarket(flowData.address?.address?.state)) {
        newData.dstLead = true
      }

      return newData
    } else {
      const { getLead, createLead, updateLead } = functions

      const queryParams = localStore?.getItem('queryParams')
      let parsedQueryParams: any = {}
      if (queryParams) {
        parsedQueryParams = JSON.parse(queryParams)
      }

      // If lead is coming from a contact info submission
      const dataToSubmit = {
        addressData: flowData?.address?.address,
        source: 'onboarding',
        fullName: parsedQueryParams.fullName,
        email: parsedQueryParams.email,
        phoneNumber: parsedQueryParams.phoneNumber,
      }

      let newLeadUuid = flowData.leadUuid

      if (!flowData.leadUuid) {
        const createLeadResponse = await createLeadHelper(
          dataToSubmit,
          parsedQueryParams,
          createLead
        )
        newLeadUuid = createLeadResponse.data.createLead.lead.uuid
        // Clear out the lead uuid in the url
        const newPath = `${ONBOARDING_PATH}/?leadUuid=${newLeadUuid}`
        window.history.replaceState(null, '', newPath)
      } else {
        await flattenUpdateLeadHelper(flowData, flowData.leadUuid, updateLead)
      }

      const getLeadResponse = await getLead({
        input: { leadUuid: newLeadUuid as string },
      })

      let newData: Partial<WebflowData> = {}
      if (getLeadResponse?.data?.lead?.lead?.address?.street) {
        const { lead } = getLeadResponse.data.lead
        if (lead.address) {
          const {
            street,
            formattedStreet,
            formattedAddress,
            streetNumber,
            unit,
            city,
            state,
            zipcode,
            latitude,
            longitude,
          } = lead.address

          const { addressUuid } = lead

          newData = {
            ...flowData,
            leadUuid: newLeadUuid,
            address: {
              address: {
                street: street as string,
                streetAddress: formattedStreet as string,
                streetNumber: streetNumber as string,
                unit: unit as string,
                unitNumber: unit as string,
                city: city as string,
                state: state as string,
                zipcode: zipcode as string,
                lat: latitude as number,
                lng: longitude as number,
                addressUuid: addressUuid as string,
                formattedAddress: formattedAddress as string,
              },
            },
          }
        }
      }

      // If it's not a valid market, this is a DST lead.
      if (!isValidMarket(newData.address?.address?.state!)) {
        newData.dstLead = true
      }

      // If they came through the initial contact info flow, we can redirect for
      // a duplicate lead
      if (flowData.initialContactInfo) {
        return handleRedirectDuplicateToOfferPage(newData, functions)
      }

      return newData
    }
  },
}

export const isExistingAddressWithCompletedNonDstLead = (
  leads: SearchLeadsByAddressLead[]
) =>
  !!leads?.some((currentLead: SearchLeadsByAddressLead) => {
    try {
      let isDstLead = false
      if (currentLead.answers) {
        const parsedJsonAnswers = JSON.parse(currentLead.answers)
        isDstLead = parsedJsonAnswers.dstLead === true
      }
      return (
        !isDstLead &&
        currentLead.fullName &&
        (currentLead.phoneNumber || currentLead.email) &&
        currentLead.slackThreadUrl
      )
    } catch (e) {
      Sentry.captureException(
        new Error('Failed to parse malformed answer for lead'),
        {
          extra: {
            leadUuid: currentLead.uuid,
          },
        }
      )
    }
    return false
  })

export const duplicateAddressVerificationStep: StepConfig<
  WebflowData,
  WebflowFunctions,
  WebflowStepProps<StaticOnboardingBackgroundProps>,
  InjectedWebflowStepProps
> = {
  ...basicProps('duplicateAddressVerification'),
  onBack: () => {
    window.onbeforeunload = null
    navigate(HOMEPAGE_PATH)
  },
  componentProps: {
    stepName: 'contactInfo',
    headline: `Request an offer in 5 minutes.`,
    description:
      'We use this contact info to send an official offer, underwritten by our experts, within 24 hours.',
    darkTheme: true,
    progress: 'address',
    spacing: 3,
    backgroundComponent: StaticOnboardingBackground,
    backgroundComponentProps: {
      src: OnboardingSplash,
      applyFilter: true,
    },
    inputConfigs: [
      {
        name: 'title',
        type: InputType.CustomComponent,
        required: true,
        gridItemProps: {
          display: {
            md: 'none',
          },
        },
        props: {
          component: (
            <Typography
              variant="h1"
              color="midnightBlue.main"
              pb={{ xs: '24px', sm: '40px' }}
            >
              Request an offer in 5 minutes
            </Typography>
          ),
        },
      },
      {
        name: 'fullName',
        type: InputType.Text,
        required: true,
        props: {
          label: 'Full Name',
          size: 'large',
        },
        mobileProps: {
          size: 'small',
        },
      },
      {
        name: 'email',
        type: InputType.Text,
        required: true,
        props: {
          label: 'Email',
          format: 'email',
          size: 'large',
        },
        mobileProps: {
          size: 'small',
        },
      },
      {
        name: 'phoneNumber',
        type: InputType.Text,
        required: false,
        props: {
          label: 'Phone Number (optional)',
          format: 'phone',
          size: 'large',
        },
        mobileProps: {
          size: 'small',
        },
      },
    ],
  },
  renderIf: async (
    flowData: Partial<WebflowData>,
    functions: WebflowFunctions
  ) => {
    // If there's another lead with the same address, we need to
    // render this step
    const { address, duplicateAddressVerification } = flowData

    // If we've already tried verification on entering the property address, skip this
    if (duplicateAddressVerification?.fullName) {
      return false
    }
    const { searchLeadsByAddress } = functions
    const sameAddressLeadsResponse = await searchLeadsByAddress({
      input: { addressUuid: address?.address?.addressUuid as string },
    })
    return (
      isExistingAddressWithCompletedNonDstLead(
        (sameAddressLeadsResponse?.data?.searchLeadsByAddress
          ?.leads as SearchLeadsByAddressLead[]) || []
      ) &&
      !shouldRedirectToAgentError(
        (sameAddressLeadsResponse?.data?.searchLeadsByAddress
          ?.leads as SearchLeadsByAddressLead[]) || []
      )
    )
  },
  onCompletion: async (
    flowData: Partial<WebflowData>,
    functions: WebflowFunctions
  ) => handleRedirectDuplicateToOfferPage(flowData, functions),
}

export const homeDetailsStep: StepConfig<
  WebflowData,
  WebflowFunctions,
  WebflowStepProps<PropertyDetailBackgroundProps>,
  InjectedWebflowStepProps
> = {
  ...basicProps('homeDetails'),
  prerender: async (
    flowData: Partial<WebflowData>,
    functions: WebflowFunctions
  ) => {
    // Preloads the google street view image url
    const { formattedAddress } = flowData.address?.address!
    let streetViewImageUrl = PlaceholderHouse
    const streetviewMetadataUrl = generateGoogleMapUrl({
      type: 'streetview/metadata',
      location: formattedAddress,
      key: GOOGLE_MAPS_API_KEY,
    })

    try {
      const response = await fetch(streetviewMetadataUrl)
      const resultData = await response.json()
      if (resultData.status === 'OK') {
        streetViewImageUrl = generateGoogleMapUrl({
          type: 'streetview',
          location: formattedAddress,
          size: '800x650',
          key: GOOGLE_MAPS_API_KEY,
          fov: 80,
        })
      }
    } catch (e) {
      console.error('Failed to find street view image')
    }

    if (!flowData.prefillData) {
      try {
        const { getHomeDetails } = functions

        const { streetNumber, streetAddress, city, state, zipcode } =
          flowData.address!.address

        const addressInput = {
          streetAddress: `${streetNumber} ${streetAddress}`,
          city,
          state,
          zip: zipcode,
        }

        const { data: prefillResponse } = await getHomeDetails({
          homeDetailsInput: addressInput,
        })

        let homeDetailsData: any = {}
        const prefillData: any = {}
        let basementFormatted = 'none'
        if (prefillResponse) {
          homeDetailsData = prefillResponse?.homeDetails?.homeDetails
          const {
            squareFootage,
            bedrooms: bedCount,
            fullBathrooms: bathCount,
            halfBathrooms: halfBathCount,
            basement,
            yearBuilt,
            buildingQualityScore,
            buildingConditionScore,
          } = prefillResponse.homeDetails?.homeDetails as Core_HomeDetails

          prefillData.prefillSquareFootage = squareFootage
          prefillData.prefillBedCount = bedCount
          prefillData.prefillBathCount = bathCount
          prefillData.prefillHalfBathCount = halfBathCount
          prefillData.prefillBasement = basement
          prefillData.prefillYearBuilt = yearBuilt
          prefillData.prefillBuildingQualityScore = buildingQualityScore
          prefillData.prefillBuildingConditionScore = buildingConditionScore

          const basementResp = basement?.toLowerCase() || 'none'
          if (basementResp.includes('partial')) {
            basementFormatted = 'partiallyFinished'
          } else if (basementResp.includes('no basement')) {
            basementFormatted = 'none'
          } else if (basementResp.includes('unfinished')) {
            basementFormatted = 'unfinished'
          } else {
            basementFormatted = 'fullyFinished'
          }
        }
        const newWebflowData = {
          ...flowData,
          homeDetails: {
            ...homeDetailsData,
            bedCount: homeDetailsData.bedrooms || '',
            bathCount:
              homeDetailsData.fullBathrooms + homeDetailsData.halfBathrooms ||
              '',
            squareFootage: homeDetailsData.squareFootage || '',
            basement: basementFormatted,
          },
          prefillData,
          streetViewImageUrl,
        }
        return newWebflowData
      } catch (e) {
        return {
          ...flowData,
          streetViewImageUrl,
        }
      }
    } else {
      return {
        ...flowData,
        streetViewImageUrl,
      }
    }
  },
  injectProps: (flowData: Partial<WebflowData>) => {
    const { streetViewImageUrl } = flowData

    return {
      flowData,
      stepData: flowData.homeDetails || {},
      backgroundComponentProps: {
        streetViewImageUrl,
        backgroundTransition: true,
      },
    }
  },
  onCompletion: async (
    flowData: Partial<WebflowData>,
    functions: WebflowFunctions
  ) => {
    const { homeDetails } = flowData
    const dstLead =
      !isValidMarket(flowData.address!.address.state) ||
      ['duplex', 'triplex', 'fourplex', 'condo', 'townhouse', 'other'].includes(
        homeDetails?.propertyType as string
      )

    const newData = {
      ...flowData,
      dstLead,
    }
    const { updateLead } = functions
    await flattenUpdateLeadHelper(
      newData,
      flowData.leadUuid as string,
      updateLead
    )
    return newData
  },
  componentProps: {
    stepName: 'homeDetails',
    progress: 'address',
    headline: 'Nice house!',
    description: `If you don't know the specifics, give us your best guesses, and our professional underwriters will cross reference them with our database.`,
    prefillDescription:
      'We collect information from property tax records and the MLS (Multiple Listing Services), but we want to ensure your details are right.',
    spacing: 3,
    backgroundComponent: PropertyDetailBackground,
    preprocessInputConfigs: (
      inputConfigs: AnyInputConfig[],
      flowData: Partial<WebflowData>
    ) =>
      // We use this to inject the address into the question
      [
        {
          name: 'homeDetailsAddressQuestion',
          type: InputType.CustomComponent,
          props: {
            component: (
              <Typography variant="h4">
                {flowData.address?.address?.formattedAddress} is a...
              </Typography>
            ),
          },
        },
        ...inputConfigs,
      ],
    inputConfigs: [
      {
        name: 'bedCount',
        type: InputType.Text,
        required: true,
        gridItemProps: {
          xs: 12,
          sm: 6,
        },
        props: {
          'aria-label': 'Bed Count',
          format: 'number',
          placeholder: '0',
          sx: {
            maxWidth: '80px',
          },
          centered: true,
        },
        mobileProps: {
          sx: {
            maxWidth: 'unset',
            width: '154px',
          },
        },
        inputDecoratorProps: {
          icon: BedroomIcon,
          postfix: 'bed',
        },
      },
      {
        name: 'bathCount',
        type: InputType.Text,
        required: true,
        gridItemProps: {
          xs: 12,
          sm: 6,
          display: {
            xs: 'block',
            sm: 'flex',
          },
          justifyContent: 'flex-end',
        },
        props: {
          'aria-label': 'Bath Count',
          format: 'number',
          placeholder: '0',
          sx: {
            maxWidth: '80px',
          },
          centered: true,
        },
        mobileProps: {
          sx: {
            maxWidth: 'unset',
            width: '154px',
          },
        },
        inputDecoratorProps: {
          icon: BathroomIcon,
          postfix: 'bath',
        },
      },
      {
        name: 'squareFootage',
        type: InputType.Text,
        required: true,
        props: {
          'aria-label': 'Square Footage',
          format: 'number',
          placeholder: '0',
          centered: true,
          sx: {
            maxWidth: '176px',
          },
        },
        mobileProps: {
          sx: {
            maxWidth: 'unset',
            width: '154px',
          },
        },
        inputDecoratorProps: {
          icon: DimensionsIcon,
          postfix: 'sq ft',
          postfixAdornment: '(above ground)',
        },
        inputDecoratorMobileProps: {
          postfix: 'sq ft',
          postfixAdornment: '',
        },
      },
      {
        name: 'propertyType',
        type: InputType.Dropdown,
        required: true,
        inputDecoratorProps: {
          icon: HouseOutlinedIcon,
        },
        props: {
          'aria-label': 'Property Type',
          options: [
            {
              label: 'single-family home',
              value: 'singlefamily',
            },
            {
              label: 'duplex',
              value: 'duplex',
            },
            {
              label: 'triplex',
              value: 'triplex',
            },
            {
              label: 'fourplex',
              value: 'fourplex',
            },
            {
              label: 'attached townhouse',
              value: 'townhouse',
            },
            {
              label: 'condo',
              value: 'condo',
            },
            {
              label: 'other',
              value: 'other',
            },
          ],
        },
      },
    ],
  },
}

export const propertyConditionStep: StepConfig<
  WebflowData,
  WebflowFunctions,
  WebflowStepProps<OnboardingBackgroundProps>,
  InjectedWebflowStepProps
> = {
  ...basicProps('propertyCondition'),
  componentProps: {
    stepName: 'propertyCondition',
    progress: 'condition',
    backgroundComponent: ConditionBackground,
    preprocessInputConfigs: (
      inputConfigs: AnyInputConfig[],
      flowData: Partial<WebflowData>
    ) =>
      // We use this to inject the address into the question
      [
        {
          name: 'propertyConditionQuestion',
          type: InputType.CustomComponent,
          props: {
            component: (
              <Typography variant="h4">
                The condition of {flowData.address?.address?.streetNumber}{' '}
                {flowData.address?.address?.streetAddress} is...
              </Typography>
            ),
          },
        },
        ...inputConfigs,
      ],
    inputConfigs: [
      {
        name: 'propertyCondition',
        type: InputType.RadioSelect,
        required: true,
        props: {
          options: [
            {
              label: 'Near perfect',
              subLabel: 'Like-new home, newly renovated, and move-in ready.',
              value: '5 - pristine',
            },
            {
              label: 'Normal wear and tear',
              subLabel:
                'Fully functional and livable with no immediate cosmetic mechanical work needed.',
              value: '4 - great',
            },
            {
              label: 'Needs minor repairs',
              subLabel:
                'Livable, but cosmetic and mechanical work is needed in the future.',
              value: '3 - average',
            },
            {
              label: 'Needs major work',
              subLabel:
                'Dated, with cosmetic and appliances needing work, but no immediate issues.',
              value: '2 - below average',
            },
            {
              label: 'Not habitable',
              subLabel:
                'Home is in disrepair and requires complete renovation.',
              value: '1 - requires complete renovation',
            },
          ],
        },
      },
    ],
  },
}

export const additionalInfoStep: StepConfig<
  WebflowData,
  WebflowFunctions,
  WebflowStepProps<StaticOnboardingBackgroundProps>,
  InjectedWebflowStepProps
> = {
  ...basicProps('additionalInfo'),
  componentProps: {
    stepName: 'additionalInfo',
    progress: 'financials',
    darkTheme: true,
    backgroundComponent: StaticOnboardingBackground,
    backgroundComponentProps: {
      src: OverheadHouse,
      hideTabletBackground: true,
    },
    inputConfigs: [
      {
        name: 'additionalInfo',
        type: InputType.Text,
        labelText: `Is there anything else you'd like to tell us about the property?`,
        props: {
          multiline: true,
          rows: 5,
          placeholder:
            'More info about recent or needed updates? This information will allow us to give you a more accurate offer.',
        },
      },
    ],
  },
}

export const hasMortgageStep: StepConfig<
  WebflowData,
  WebflowFunctions,
  WebflowStepProps<StaticOnboardingBackgroundProps>,
  InjectedWebflowStepProps
> = {
  ...basicProps('hasMortgage'),
  componentProps: {
    stepName: 'hasMortgage',
    progress: 'financials',
    darkTheme: true,
    backgroundComponent: StaticOnboardingBackground,
    backgroundComponentProps: {
      src: OverheadHouse,
      hideTabletBackground: true,
      backgroundTransition: true,
    },
    inputConfigs: [
      {
        name: 'hasMortgage',
        type: InputType.RadioSelect,
        required: true,
        props: {
          label: 'Do you have a mortgage on this property?',
          row: true,
          options: [
            {
              label: 'No',
              value: 'no',
            },
            {
              label: 'Yes',
              value: 'yes',
            },
          ],
        },
      },
    ],
  },
}

export const mortgageAmountStep: StepConfig<
  WebflowData,
  WebflowFunctions,
  WebflowStepProps<StaticOnboardingBackgroundProps>,
  InjectedWebflowStepProps
> = {
  ...basicProps('mortgageAmount'),
  renderIf: async (flowData: Partial<WebflowData>) =>
    flowData.hasMortgage?.hasMortgage === 'yes',
  componentProps: {
    stepName: 'mortgageAmount',
    progress: 'financials',
    spacing: 4,
    darkTheme: true,
    backgroundComponent: StaticOnboardingBackground,
    backgroundComponentProps: {
      src: OverheadHouse,
      hideTabletBackground: true,
    },
    inputConfigs: [
      {
        name: 'mortgageAmount',
        type: InputType.Text,
        required: true,
        labelText: 'What is the outstanding mortgage balance?',
        labelOptionalText: '(An estimate is okay for now.)',
        props: {
          placeholder: '$0',
          format: 'dollars',
        },
      },
    ],
  },
}

export const leaseDetailsStep: StepConfig<
  WebflowData,
  WebflowFunctions,
  WebflowStepProps<StaticOnboardingBackgroundProps>,
  InjectedWebflowStepProps
> = {
  ...basicProps('leaseDetails'),
  renderIf: async (flowData: Partial<WebflowData>) =>
    flowData.hasTenant?.hasTenant === 'true',
  componentProps: {
    stepName: 'leaseDetails',
    progress: 'financials',
    spacing: 3,
    backgroundComponent: StaticOnboardingBackground,
    backgroundComponentProps: {
      src: OverheadHouse,
      hideTabletBackground: true,
    },
    inputConfigs: [
      {
        name: 'leaseType',
        type: InputType.RadioSelect,
        props: {
          label: 'The lease is...',
          row: true,
          options: [
            {
              label: 'Month-to-month',
              value: 'mtm',
            },
            {
              label: 'Long-term',
              value: 'lease',
            },
          ],
        },
      },
      {
        name: 'rentAmount',
        type: InputType.Text,
        required: true,
        labelText: 'The rent is',
        props: {
          format: 'dollars',
          placeholder: '$0',
          centered: true,
          sx: {
            width: '180px',
          },
        },
        inputDecoratorProps: {
          postfix: 'rent / month',
        },
      },
      {
        name: 'leaseEnd',
        type: InputType.DatePicker,
        required: true,
        labelText: 'The lease ends',
        renderIf: (watchedFields: any) => watchedFields.leaseType !== 'mtm',
        props: {
          placeholder: 'MM/DD/YYYY',
          disablePast: true,
          sx: {
            width: '180px',
          },
          centered: true,
        },
      },
    ],
  },
}

export const contactInfoStep: StepConfig<
  WebflowData,
  WebflowFunctions,
  WebflowStepProps<StaticOnboardingBackgroundProps>,
  InjectedWebflowStepProps
> = {
  ...basicProps('contactInfo'),
  renderIf: async (flowData: Partial<WebflowData>) =>
    !flowData.initialContactInfo &&
    !flowData.duplicateAddressVerification &&
    !flowData.dstLead,
  componentProps: {
    stepName: 'contactInfo',
    headline: `Get your offer in 24 hours.`,
    description: `We'll send you a personalized offer, underwritten by our valuation experts, within 24 hours.`,
    progress: 'profile',
    spacing: 3,
    darkTheme: true,
    backgroundComponent: StaticOnboardingBackground,
    backgroundComponentProps: {
      src: OnboardingSplash,
      applyFilter: true,
      backgroundTransition: true,
    },
    inputConfigs: [
      {
        name: 'title',
        type: InputType.CustomComponent,
        required: true,
        gridItemProps: {
          display: {
            md: 'none',
          },
        },
        props: {
          component: (
            <Typography
              variant="h1"
              color="midnightBlue.main"
              pb={{ xs: '24px', sm: '40px' }}
            >
              Get your offer in 24 hours.
            </Typography>
          ),
        },
      },
      {
        name: 'fullName',
        type: InputType.Text,
        required: true,
        props: {
          label: 'Full Name',
          size: 'large',
        },
        mobileProps: {
          size: 'small',
        },
      },
      {
        name: 'email',
        type: InputType.Text,
        required: true,
        props: {
          label: 'Email',
          format: 'email',
          size: 'large',
        },
        mobileProps: {
          size: 'small',
        },
      },
      {
        name: 'phoneNumber',
        type: InputType.Text,
        required: false,
        props: {
          label: 'Phone Number (optional)',
          format: 'phone',
          size: 'large',
        },
        mobileProps: {
          size: 'small',
        },
      },
    ],
  },
}

export const selfReportedValueStep: StepConfig<
  WebflowData,
  WebflowFunctions,
  WebflowStepProps<StaticOnboardingBackgroundProps>,
  InjectedWebflowStepProps
> = {
  ...basicProps('selfReportedValue'),
  componentProps: {
    stepName: 'mortgageAmount',
    progress: 'financials',
    spacing: 4,
    darkTheme: true,
    backgroundComponent: StaticOnboardingBackground,
    backgroundComponentProps: {
      src: OverheadHouse,
      hideTabletBackground: true,
    },
    inputConfigs: [
      {
        name: 'selfReportedValue',
        type: InputType.Text,
        labelText: 'What do you estimate to be the value of your property?',
        labelOptionalText: '(optional)',
        props: {
          placeholder: '$0',
          format: 'dollars',
        },
      },
    ],
  },
}

export const attributionStep: StepConfig<
  WebflowData,
  WebflowFunctions,
  WebflowStepProps<LoadingBackgroundProps>,
  InjectedWebflowStepProps
> = {
  ...basicProps('attribution'),
  componentProps: {
    stepName: 'attribution',
    progress: 'complete',
    ctaText: 'Submit',
    backgroundComponent: LoadingBackground,
    backgroundComponentProps: {
      backgroundTransition: true,
      hideTabletBackground: true,
    },
    inputConfigs: [
      {
        name: 'attribution',
        type: InputType.RadioSelect,
        required: true,
        props: {
          label: `While we’re generating your preliminary offer, do you mind sharing how you heard about Overmoon?`,
          options: [
            {
              label: 'Google search result',
              value: 'internetSearch',
            },
            {
              label: 'Email',
              value: 'email',
            },
            {
              label: 'Word of mouth',
              value: 'wordOfMouth',
            },
            {
              label: 'Postcard or letter',
              value: 'directMail',
            },
            {
              label: 'Facebook or Instagram ad',
              value: 'facebookAd',
            },
            {
              label: 'Google ad',
              value: 'googleAd',
            },
            {
              label: 'Realtor or Broker',
              value: 'realtor',
            },
            {
              label: 'Other',
              value: 'other',
            },
          ],
        },
      },
      {
        name: 'attributionDetail',
        type: InputType.Text,
        required: true,
        renderIf: (watchedFields: any) => watchedFields.attribution === 'other',
        props: {
          placeholder: 'Please specify how you heard about Overmoon.',
        },
      },
    ],
  },
}

export const dstAttributionStep: StepConfig<
  WebflowData,
  WebflowFunctions,
  WebflowStepProps<StaticOnboardingBackgroundProps>,
  InjectedWebflowStepProps
> = {
  ...basicProps('attribution'),
  renderIf: async (flowData: Partial<WebflowData>) => !!flowData.dstLead,
  componentProps: {
    stepName: 'attribution',
    progress: 'complete',
    backgroundComponent: StaticOnboardingBackground,
    backgroundComponentProps: {
      src: OnboardingSplash,
      applyFilter: true,
    },
    inputConfigs: [
      {
        name: 'attribution',
        type: InputType.RadioSelect,
        required: true,

        props: {
          label: `How did you hear about Overmoon?`,
          options: [
            {
              label: 'Google search result',
              value: 'internetSearch',
            },
            {
              label: 'Email',
              value: 'email',
            },
            {
              label: 'Word of mouth',
              value: 'wordOfMouth',
            },
            {
              label: 'Postcard or letter',
              value: 'directMail',
            },
            {
              label: 'Facebook or Instagram ad',
              value: 'facebookAd',
            },
            {
              label: 'Google ad',
              value: 'googleAd',
            },
            {
              label: 'Realtor or broker',
              value: 'realtor',
            },
            {
              label: 'Other',
              value: 'other',
            },
          ],
        },
      },
      {
        name: 'attributionDetail',
        type: InputType.Text,
        required: true,
        renderIf: (watchedFields: any) => watchedFields.attribution === 'other',
        props: {
          placeholder: 'Please specify how you heard about Overmoon.',
        },
      },
    ],
  },
}

export const thankYouStep: StepConfig<
  WebflowData,
  WebflowFunctions,
  WebflowStepProps<LoadingBackgroundProps>,
  InjectedWebflowStepProps
> = {
  ...basicProps('thankYou'),
  componentProps: {
    stepName: 'thankYou',
    progress: 'complete',
    backgroundComponent: LoadingBackground,
    backgroundComponentProps: {
      hideTabletBackground: true,
      ready: true,
    },
    autoProgressTime: 2000,
    inputConfigs: [
      {
        name: 'thankYou',
        type: InputType.CustomComponent,
        props: {
          component: (
            <Box display="flex" flexDirection="column" gap="16px">
              <Typography variant="h1" color="midnightBlue.main">
                Thank you!
              </Typography>
              <Typography variant="h4">
                You will be automatically redirected to your offer page shortly.
              </Typography>
              <CircularProgress />
            </Box>
          ),
        },
      },
    ],
  },
  onCompletion: async (
    flowData: Partial<WebflowData>,
    functions: WebflowFunctions
  ) => {
    const { updateLead, completedLeadSideEffects } = functions
    const { leadUuid } = flowData

    if (localStore) {
      const completedLeadParams = {
        streetAddress: flowData.address?.address?.streetAddress,
        streetNumber: flowData.address?.address?.streetNumber,
        leadUuid,
      }
      localStore?.setItem(
        'completedLeadParams',
        JSON.stringify(completedLeadParams)
      )
    }
    await flattenUpdateLeadHelper(flowData, flowData.leadUuid!, updateLead)
    await completedLeadSideEffects({
      variables: {
        completedLeadSideEffectsInput: {
          leadUuid: leadUuid!,
          sendWelcomeEmail: true,
        },
      },
    })

    window.onbeforeunload = null
    window.open(`${OFFER_PAGE_PATH}/${leadUuid}`, '_self')
    if (window.top) {
      window.top.postMessage('Close Modal', '*')
    }
    // This line ensures that the next step doesn't render.
    // @ts-ignore
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    await new Promise((resolve) => {})

    return flowData
  },
}

export const dstLeadGenStep: StepConfig<
  WebflowData,
  WebflowFunctions,
  WebflowStepProps<StaticOnboardingBackgroundProps>,
  InjectedWebflowStepProps
> = {
  ...basicProps('dstLeadGen'),
  renderIf: async (flowData: Partial<WebflowData>) => !!flowData.dstLead,
  prerender: async (flowData: Partial<WebflowData>) => ({
    ...flowData,
    dstLeadGen: {
      ...flowData.contactInfo!,
    },
  }),
  componentProps: {
    stepName: 'dstLeadGen',
    progress: 'profile',
    darkTheme: true,
    spacing: 3,
    backgroundComponent: StaticOnboardingBackground,
    backgroundComponentProps: {
      src: OnboardingSplash,
      applyFilter: true,
    },
    ctaText: 'Submit',
    inputConfigs: [
      {
        name: 'title',
        type: InputType.CustomComponent,
        required: true,
        gridItemProps: {
          display: {
            md: 'none',
          },
        },
        props: {
          component: (
            <Typography
              variant="h4"
              color="midnightBlue.main"
              pb={{ xs: '24px', sm: '40px' }}
            >
              {`We only take single family homes in ${prettyPrintStatesAbbr(
                'and'
              )}. Leave your contact info and we'll be in touch soon!`}
            </Typography>
          ),
        },
      },
      {
        name: 'fullName',
        type: InputType.Text,
        required: true,
        props: {
          label: 'Full Name',
          size: 'large',
        },
        mobileProps: {
          size: 'small',
        },
      },
      {
        name: 'email',
        type: InputType.Text,
        required: true,
        props: {
          label: 'Email',
          format: 'email',
          size: 'large',
        },
        mobileProps: {
          size: 'small',
        },
      },
      {
        name: 'phoneNumber',
        type: InputType.Text,
        required: false,
        props: {
          label: 'Phone Number (optional)',
          format: 'phone',
          size: 'large',
        },
        mobileProps: {
          size: 'small',
        },
      },
    ],
  },
  onCompletion: async (
    flowData: Partial<WebflowData>,
    functions: WebflowFunctions
  ) => {
    const { updateLead, completedLeadSideEffects } = functions
    const { leadUuid } = flowData

    await flattenUpdateLeadHelper(flowData, flowData.leadUuid!, updateLead)
    await completedLeadSideEffects({
      variables: {
        completedLeadSideEffectsInput: {
          leadUuid: leadUuid!,
          sendWelcomeEmail: false,
        },
      },
    })

    return flowData
  },
}

export const dstThankYouStep: StepConfig<
  WebflowData,
  WebflowFunctions,
  WebflowStepProps<StaticOnboardingBackgroundProps>,
  InjectedWebflowStepProps
> = {
  ...basicProps('thankYou'),
  renderIf: async (flowData: Partial<WebflowData>) => !!flowData.dstLead,
  componentProps: {
    stepName: 'thankYou',
    progress: 'complete',
    ctaText: 'Finish',
    hideBack: true,
    backgroundComponent: StaticOnboardingBackground,
    backgroundComponentProps: {
      src: OnboardingSplash,
      applyFilter: true,
    },
    inputConfigs: [
      {
        name: 'thankYou',
        type: InputType.CustomComponent,
        props: {
          component: (
            <Box display="flex" flexDirection="column" gap="16px">
              <Typography variant="h1" color="midnightBlue.main">
                Thank you!
              </Typography>
              <Typography variant="h4">We will be in touch soon.</Typography>
            </Box>
          ),
        },
      },
    ],
  },
  onCompletion: async (
    flowData: Partial<WebflowData>,
    functions: WebflowFunctions
  ) => {
    const { updateLead } = functions

    await flattenUpdateLeadHelper(flowData, flowData.leadUuid!, updateLead)

    window.onbeforeunload = null
    window.open(HOMEPAGE_PATH, '_self')
    if (window.top) {
      window.top.postMessage('Close Modal', '*')
    }
    // This line ensures that the next step doesn't render.
    // @ts-ignore
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    await new Promise((resolve) => {})

    return flowData
  },
}

export const webflowInputConfigs = [
  initialContactInfoStep,
  enterAddressStep,
  duplicateAddressVerificationStep,
  homeDetailsStep,
  dstAttributionStep,
  dstLeadGenStep,
  dstThankYouStep,
  propertyConditionStep,
  hasMortgageStep,
  mortgageAmountStep,

  selfReportedValueStep,
  additionalInfoStep,

  contactInfoStep,
  dstAttributionStep,
  dstLeadGenStep,
  dstThankYouStep,

  attributionStep,
  thankYouStep,
]
