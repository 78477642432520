import { PageContainer } from '@flock/shared-ui'
import React, { useEffect } from 'react'
import WebflowForm from '../components/WebflowOnboardingComponents/WebflowForm'
import { webflowInputConfigs } from '../components/WebflowOnboardingComponents/webflowOnboardingInputsConfigs'
import '../styles/onboarding.css'

export const Head = () => (
  <>
    <script src="https://api.mapbox.com/mapbox.js/v3.3.1/mapbox.js" />
    <link
      href="https://api.mapbox.com/mapbox.js/v3.3.1/mapbox.css"
      rel="stylesheet"
    />
  </>
)

const Onboarding = () => {
  useEffect(() => {
    const isBrowser = typeof window !== 'undefined'

    // @ts-ignore
    const isCypressTestingWindow = isBrowser && window && window?.Cypress

    // Prompt before leaving page
    if (!isCypressTestingWindow) {
      window.onbeforeunload = () => true
      return () => {
        window.onbeforeunload = null
      }
    }
    return () => {}
  }, [])

  return (
    <PageContainer title="Overmoon | Onboarding" trackingName="onboarding">
      <WebflowForm stepConfigs={webflowInputConfigs} />
    </PageContainer>
  )
}

export default Onboarding
